'use strict';
const containers = document.querySelectorAll('.Accordions');

const handleToggleClick = event => {
  const accordion = event.target.closest('.accordion');
  const isAccordionOpen = !accordion.classList.contains('accordionClosed');
  const container = event.target.closest('.Accordions');
  const allAccordions = container.querySelectorAll('.accordion');

  // close all accordions in section
  allAccordions.forEach(item => {
    item.classList.add('accordionClosed');
  });

  // open clicked accordion, if it was closed (or close it if it was open)
  if (!isAccordionOpen) {
    accordion.classList.remove('accordionClosed');
  }
};

containers.forEach(container => {
  const accordionToggles = container.querySelectorAll('.accordionTitleWrap');

  accordionToggles.forEach(toggle => {
    toggle.addEventListener('click', handleToggleClick);
  });
});
