'use strict';
const containers = document.querySelectorAll('.VideoAndText');

const handleVideoPosterClick = (event) => {
  const container = event.target.closest(".VideoAndText");
  const player = container.querySelectorAll('.videoPlayer')[0];

  // show iframe
  player.classList.add('isShown');
  // load video
  player.src = player.getAttribute('srctoplay');

};

// attach button click event listener to each button in container
containers.forEach(container => {
  const button = container.querySelectorAll('.videoPoster')[0];
  button.addEventListener('click', handleVideoPosterClick);
});
