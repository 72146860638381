'use strict';
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const container = document.querySelectorAll('.HeaderGraphicDiagonal')[0];
if (container) {
  const button = container.querySelectorAll('.showHideButton')[0];

  // school footer exists on page, use it as the end trigger
  let endElement = document.querySelectorAll('.SchoolFooter');
  // if not found, use the global footer
  if (endElement.length === 0) {
    endElement = document.querySelectorAll('.Footer');
  }
  // get the first element
  endElement = endElement[0];

  // hide button initially
  gsap.to(button, {
    duration: 0,
    xPercent: 101,
  })

  const trigger = ScrollTrigger.create({
    trigger: container,
    start: 'top+=100 top',
    endTrigger: endElement,
    end: 'top bottom',
    onEnter: self => {
      // show button
      gsap.to(button, {
        xPercent: 0,
        ease: "sine.inOut",
        duration: .4
      })
    },
    onEnterBack: self => {
      // show button
      gsap.to(button, {
        xPercent: 0,
        ease: "sine.inOut",
        duration: .4
      })
    },
    onLeave: self => {
      // hide button
      gsap.to(button, {
        xPercent: 101,
        ease: "sine.inOut",
        duration: .4
      })
    },
    onLeaveBack: self => {
      // do nothing
    }
  });
}
