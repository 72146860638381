'use strict';


let container = document.querySelectorAll('.EnrollmentCity');
if (container.length > 0) {
  container = document.querySelectorAll('.stepsWrapper')[0];

  const outerNavItems = container.querySelectorAll('.stepButton');
  const innerNavItems = container.querySelectorAll('.innerNavButton');
  const steps = container.querySelectorAll('.step');

  const hideAllSteps = () => {
    steps.forEach(step => {
      step.classList.add('isHidden');
    });
    outerNavItems.forEach(item => {
      item.classList.add('isHidden');
    });
  }
  const showStep = (index) => {
    steps[index].classList.remove('isHidden');
    outerNavItems[index].classList.remove('isHidden');
  }

  const handleOuterNavClick = (event) => {
    const clickedItem = event.target.closest('.stepButton');
    hideAllSteps();
    showStep(clickedItem.dataset.step);
  }

  const handleInnerNavClick = (event) => {
    const clickedItem = event.target.closest('.innerNavButton');
    const parentStep = clickedItem.closest('.step');
    let nextStep = parseInt(parentStep.dataset.step);


    if(clickedItem.classList.contains('next')) {
      nextStep += 1;
    } else {
      nextStep -= 1;
    }

    hideAllSteps();
    showStep(nextStep);

    // scroll to top of section
    window.scrollTo({
      top: container.offsetTop - 200,
      behavior: 'smooth'
    });
  }

  // make only first item active
  outerNavItems.forEach((item, index) => {
    if(index > 0) {
      item.classList.add('isHidden');
    }
  })

  // hide all steps except the first
  steps.forEach((step, index) => {
    if(index > 0){
      step.classList.add('isHidden');
    }
  });

  // add click events to outer nav items
  outerNavItems.forEach((item, index) => {
    item.addEventListener('click', handleOuterNavClick);
  });

  // add click events to inner nav items
  innerNavItems.forEach((item, index) => {
    item.addEventListener('click', handleInnerNavClick);
  });

}
