'use strict';
import Swiper from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';

const containers = document.querySelectorAll('.PhotoAndTextSlideshow');
const swiperInstances = [];

containers.forEach((container) => {
  const swiper = new Swiper(container.querySelectorAll('.swiper')[0], {
    modules: [Navigation, Autoplay],
    navigation: {
      nextEl: container.querySelectorAll('.swiper-button-next')[0],
      prevEl: container.querySelectorAll('.swiper-button-prev')[0],
    },
    autoplay: {
      delay: 20000,
    },
    loop: true,
  });
  swiperInstances.push(swiper);
});

