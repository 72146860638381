'use strict'
import gsap from 'gsap'

const container = document.querySelectorAll('.TopNav')[0]
const openNavButton = container.querySelectorAll('.openMenu')[0]
const closeNavButton = container.querySelectorAll('.closeMenu')[0]

const openMenu = () => {
  const tl = gsap.timeline()

  // remove items from the top bar
  const fadeOutElems = container.querySelectorAll('.majorNavItem, .divider, .openMenu')
  tl.to(fadeOutElems, { duration: 0.5, opacity: 0, display: 'none', stagger: 0.05 })

  // add the close icon
  const fadeInElems = container.querySelectorAll('.closeMenu')
  tl.to(fadeInElems, { duration: 0.5, opacity: 1, display: 'flex', stagger: 0.05 })

  // slide in menu
  const menuElem = container.querySelectorAll('.megaMenuWrap')
  const menuContent = container.querySelectorAll('.megaMenuContent .column')
  tl.set(menuElem, {
    display: 'grid',
    height: 0,
  },
  '-=1')
  tl.to(menuElem, { duration: 0.5, height: 'auto' }, '-=1')
  tl.to(menuContent, { duration: 0.5, opacity: 1, stagger: 0.05 }, '-=0.5')
}

const closeMenu = () => {
  const tl = gsap.timeline()

  // fade out content
  const fadeOutElems = container.querySelectorAll('.closeMenu')
  tl.to(fadeOutElems, { duration: 0.5, opacity: 0, display: 'none', stagger: 0.05 })
  const menuContent = container.querySelectorAll('.megaMenuContent .column')
  tl.to(menuContent, { duration: 0.5, opacity: 0, stagger: 0.05 }, '-=0.5')

  // fade in top bar
  let fadeInElems = container.querySelectorAll(".openMenu, .divider, .majorNavItem")
  tl.set(fadeInElems, { display: 'flex'})
  tl.to(fadeInElems, { duration: 0.5, opacity: 1, stagger: -0.05 })

  // slid in menu
  const menuElem = container.querySelectorAll('.megaMenuWrap')
  tl.to(menuElem, { duration: 0.5, height: '0' }, '-=1')

}

openNavButton.addEventListener('click', openMenu)
closeNavButton.addEventListener('click', closeMenu)
