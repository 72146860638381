'use strict';
/*==============================
=          GLOBAL  JS          =
==============================*/

const debounce = (func, wait) => {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const windowResizeHandler = () => {
  console.log('window size: ', window.innerWidth);
}

const debouncedWindowResizeHandler = debounce(windowResizeHandler, 200);

// Marker.io on dev and staging
if (window.location.hostname === 'regenerationschools.test' || window.location.hostname === 'regenerationschools.cdwstaging.com') {

  console.log('Staging Mode')
  window.markerConfig = {
    project: '6760749eb1668626a606898f',
    source: 'snippet'
  };

  !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","clearReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
}


if (window.location.hostname === 'regenerationschools.test') {
  console.log('Development Mode');

  // log width when window size changes
  windowResizeHandler();
  window.addEventListener('resize', debouncedWindowResizeHandler);
}
